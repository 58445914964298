@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$gray: #eee;
$dark: #556472;
$light-gray: #fafafa;
$blue: #005da3;
$black: #444;
$light-blue: #9bc4e3;
$white: #fff;
$red: #e05939;

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius; }

@mixin box-shadow($value) {
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
	-ms-box-shadow: $value;
	box-shadow: $value; }

body {
	font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
	background-color: $light-gray; }
h2 {
	font-size: 24px;
	color: $red;
	padding: 20px;
	font-weight: 600; }

h3 {
	font-size: 24px;
	color: $red;
	font-weight: 600; }

h1,h2,h3,h4,h5 {
	text-transform: uppercase; }

img {
	vertical-align: middle; }

.carousel-control {
	&.left, &.right {}
	background: none !important;
	filter: progid:none !important; }

.no-padding {
	padding: 0; }

.shadow-element {
	@include box-shadow(0 0 15px rgba(0,0,0,0.5)); }


nav {
	&.navbar {
		height: auto;
		min-height: initial; }
	&.navbar-default {
		background-color: $gray;
		border: 0;
		font-size: 13px;
		ul li {
			font-weight: bold;
			color: $dark;
			a {
				padding: 10px;
				&.social-icon {
					padding: 10px 8px; }
				&.facebook:hover {
					color: #3B589B; }
				&.instagram {
					&:hover {
						color: #3B589B; } }
				&.youtube:hover {
					color: red; }
				&.twitter:hover {
						color: #4EC9F7; } }
			span.date {
				padding: 10px;
				display: block;
				&.light {
					color: #999;
					font-weight: normal; } } } } }

.light-gray-bg {
	background-color: $light-gray; }
.gray-bg {
	background-color: $gray; }

.menu {
	.logo {
		max-height: 75px; }
	.search {
		margin-top: 20px;
		border: 1px solid $gray;
		min-width: 250px;
		max-width: 280px;
		padding: 6px 12px 6px 44px;
		@include border-radius(30px);
		padding-left: 30px; }
	.navbar-toggle {
		background-color: $blue;
		.icon-bar {
			background-color: $white; } }
	@media(max-width: 768px) {
		.navbar {
			margin-bottom: 0;
			ul li {
				&.radio a {
					color: $red; }
				a {
					font-size: 14px;
					font-weight: bold;
					color: #444;
					&:hover {
						color: $blue; } }
				&.dropdown .dropdown-content a {
					display: block;
					padding: 10px 30px;
					background-color: #eeeeee; } } } }
	@media(min-width: 768px) {
		.navbar {
			text-transform: uppercase;
			clear: both;
			text-align: center;
			margin: 0;
			ul li {
				display: inline-block;
				float: none;
				a {
					color: $black;
					font-size: 14px;
					padding: 5px;
					font-weight: bold;
					&:hover {
						color: $blue;
						background-color: transparent; } }
				&.active a {
					color: $blue;
					&.no-active {
						color: #444;
						&:hover {
							color: $blue; } } }
				&.radio a {
					color: $red;
					&:hover {
						color: $blue;
						background-color: transparent; } }
				&.active.radio a {
					color: $blue; }
				&.dropdown {
					position: relative; }
				&.dropdown:hover {
					.dropdown-content {
						display: block; } }
				.dropdown-content {
					display: none;
					position: absolute;
					background-color: #f9f9f9;
					min-width: 200px;
					box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
					z-index: 1;
					a {
						display: block;
						padding: 12px 16px;
						text-decoration: none; } } } } } }

.home-featured {
	a {
		color: $white;
		text-decoration: none; }
	.container-fluid {
		background-color: $blue;
		.large-container {
			max-width: 1600px;
			margin: 0 auto;
			.news {
				padding: 0; }
			.featured {
				background: transparent no-repeat center center;
				background-size: cover;
				@media(max-width: 767px) {
					min-height: 320px!important; }
				@media(min-width: 768px) {
					min-height: 660px; }
				position: relative;
				.title-container {
					position: absolute;
					bottom: 40px;
					left: 0;
					background-color: rgba(0,93,163,0.95);
					padding: 20px 30px;
					right: 100px;
					@media(max-width: 767px) {
						position: absolute;
						bottom: 0!important;
						background-color: rgba(0,93,163,0.8);
						padding: 20px 30px;
						width: 100%; }
					h1 {
						margin: 0 0 5px;
						font-size: 32px;
						@media(max-width: 767px) {
							font-size: 18px!important; } }
					.metadata {
						color: $light-blue;
						font-size: 18px;
						@media(max-width: 767px) {
							font-size: 14px!important; } } } }
			.services {
				padding: 0;
				.side-box {
					min-height: 220px;
					display: block;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					position: relative;
					text-transform: uppercase;
					background-color: transparent;
					.subtitle-container {
						padding: 20px;
						position: absolute;
						bottom: 0;
						background-color: rgba(0,93,163,0.6);
						width: 100%;
						h2 {
							margin: 0;
							padding: 0;
							color: $white;
							font-size: 16px; }
						p {
							color: rgba(254,254,254,0.7);
							margin: 0; } } } } } } }

.fresh-events {
	margin-bottom: 40px;
	h3 {
		padding: 40px 0;
		font-weight: 600; }
	.events-list {
		margin-bottom: 40px;
		padding-left: 0;
		list-style: none;
		li {
			padding: 5px 0 5px 90px;
			position: relative;
			border-bottom: 1px solid $gray;
			min-height: 104px;
			a {
				color: $black;
				display: block;
				&:hover {
					color: $blue;
					text-decoration: none; }
				.events-image {
					width: 75px;
					height: 75px;
					position: absolute;
					left: 0;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center; }
				img {
					position: absolute;
					left: 0;
					width: 75px;
					height: auto; }
				h4 {
					margin: 8px 0 4px;
					line-height: 150%;
					font-size: 14px;
					font-weight: 600; } }
			.publish_date {
				color: #999; } } } }

.banner-area {
	h3 {
		padding: 40px 0;
		font-weight: 600; }
	.box {
		margin-bottom: 30px;
		display: block;
		img {
			border: 1px solid #ddd; } } }

.video-display {
	text-align: center;
	padding: 20px 0 40px; }

.announcements {
	overflow: hidden;
	padding: 60px 0;
	h3 {
		padding: 0 0 40px;
		margin: 0; }
	a {
		color: $blue;
		text-decoration: none;
		font-weight: 600;
		img {
			display: block;
			margin-left: auto;
			margin-right: auto; } } }

.publishes {
	padding: 60px 0;
	h3 {
		padding: 0 0 40px;
		margin: 0;
		text-align: center;
		color: $dark; }
	a {
		color: $blue;
		text-decoration: none;
		font-weight: 600;
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-height: 180px; }
		h4 {
			margin: 8px 0 4px;
			line-height: 150%;
			font-size: 14px;
			font-weight: 600; }
		.description {
			color: #999; } } }


.site-map {
	padding: 60px 0;
	.group-list {
		vertical-align: top;
		display: inline-block;
		padding: 15px;
		@media(max-width: 767px) {
			width: 100%; }
		@media(min-width: 768px) {
			width: 20%; }
		h3 {
			font-size: 14px;
			color: #888;
			margin: 0 0 20px;
			vertical-align: bottom;
			display: table-row;
			height: 40px; }
		ul {
			list-style: none;
			padding: 0;
			li a {
				padding: 6px 0;
				color: $black;
				display: block;
				font-weight: 600;
				&:hover {
					color: $blue; } } } } }

.footer-images {
	padding: 40px;
	overflow: hidden; }

.copyright {
	float: right;
	padding: 15px; }


.page-header {
	border: 0;
	margin: 20px 0;
	padding: 20px 30px;
	h1 {
		color: $blue;
		margin: 0;
		font-size: 38px;
		font-weight: 600; } }

.page-content {
	padding-bottom: 40px;
	line-height: 160%;
	.main-content {
		img {
			max-height: 550px;
			display: block;
			margin-left: auto;
			margin-right: auto; } } }

.frm_required {
	color: $red;
	font-weight: bold; }

.frm_submit {
	margin: 10px;
	margin-left: 0;
	margin-right: 0;
	@include box-shadow(0 1px 1px $gray);
	text-shadow: none;
	padding: 6px 11px;
	@include border-radius(4px);
	font-weight: normal;
	background: $white;
	border-width: 1px;
	border-color: #cccccc;
	border-style: solid;
	line-height: normal;
	&:hover {
		background: #efefef; } }
